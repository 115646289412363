<template>
    <layout-full-width :title="$tc('summary')">

        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-header">
                        {{ $tc('summary') }}
                    </div>
                    <div class="card-body">
                        <p class="card-text">
                            Welcome to Spot Emotion.
                        <ol>
                            <li>
                                To create a new campaign click at "Campaigns" in the sidebar.
                            </li>
                            <li>
                                In Campaigns page click at "Create campaign" button at the top of the screen
                            </li>
                            <li>
                                Select campaign type you want to create.
                            </li>
                            <li>
                                Give your campaign name and click continue.
                            </li>
                            <li>
                                Your campaign configuration page should open. Here you can give your campaign Deutsche
                                Post Print-Mailing Automation name and add your recipients variable names.
                            </li>
                            <li>
                                To do so click at "Add content" button.
                            </li>
                        </ol>
                        </p>
                    </div>
                </div>
            </div>
        </div>

    </layout-full-width>
</template>

<script>
import LayoutFullWidth from "@/layouts/LayoutFullWidth";

export default {
    name: "PageSummary",
    components: {LayoutFullWidth}
}
</script>

<style lang="scss" scoped></style>