import { render, staticRenderFns } from "./PageSummary.vue?vue&type=template&id=ee0f50f4&scoped=true&"
import script from "./PageSummary.vue?vue&type=script&lang=js&"
export * from "./PageSummary.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ee0f50f4",
  null
  
)

export default component.exports